.uploadImg {
    width: 95%;
    background-color: #141b2d;
    border: 1px solid #4cceac;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 0.2rem;
    cursor: pointer;
    gap: 1rem;
    padding: 1rem;
    color: #4cceac;
  }
  .image-container {
    position: relative;
    width: 200px;
    height: 200px;
  }
  
  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .image-container:hover .overlay {
    opacity: 1;
  }
  